<template>
  <div id="particulatePollutionReport">
    <el-dialog
      :title="particulatePollutionReportFormTitle"
      width="800px"
      :visible.sync="particulatePollutionReportDialogVisible"
      :close-on-click-modal="false"
      @close="particulatePollutionReportDialogClose"
    >
    <div id="pdfDom">
      <el-form
        ref="particulatePollutionReportFormRef"
        :model="particulatePollutionReportForm"
        :rules="particulatePollutionReportFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="编号" prop="formNo">
              <el-input
                v-model="particulatePollutionReportForm.formNo"
                placeholder="请输入编号"
                clearable
                :disabled="particulatePollutionReportFormTitle !== '新增微粒污染检验报告'
                  && particulatePollutionReportFormTitle !== '修改微粒污染检验报告'
                  && particulatePollutionReportFormTitle !== '微粒污染检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="名称" prop="name">
              <el-input
                v-model="particulatePollutionReportForm.name"
                placeholder="请输入名称"
                clearable
                :disabled="particulatePollutionReportFormTitle !== '新增微粒污染检验报告'
                  && particulatePollutionReportFormTitle !== '修改微粒污染检验报告'
                  && particulatePollutionReportFormTitle !== '微粒污染检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格型号" prop="spec">
              <el-input
                v-model="particulatePollutionReportForm.spec"
                placeholder="请输入规格型号"
                clearable
                :disabled="particulatePollutionReportFormTitle !== '新增微粒污染检验报告'
                  && particulatePollutionReportFormTitle !== '修改微粒污染检验报告'
                  && particulatePollutionReportFormTitle !== '微粒污染检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批号" prop="batchNo">
              <el-input
                v-model="particulatePollutionReportForm.batchNo"
                placeholder="请输入批号"
                clearable
                :disabled="particulatePollutionReportFormTitle !== '新增微粒污染检验报告'
                  && particulatePollutionReportFormTitle !== '修改微粒污染检验报告'
                  && particulatePollutionReportFormTitle !== '微粒污染检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="抽检数量" prop="inspectionQuantity">
              <el-input
                v-model="particulatePollutionReportForm.inspectionQuantity"
                placeholder="请输入抽检数量"
                clearable
                :disabled="particulatePollutionReportFormTitle !== '新增微粒污染检验报告'
                  && particulatePollutionReportFormTitle !== '修改微粒污染检验报告'
                  && particulatePollutionReportFormTitle !== '微粒污染检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报告日期" prop="reportDate">
              <el-date-picker
                v-model="particulatePollutionReportForm.reportDate"
                placeholder="请选择报告日期"
                value-format="yyyy-MM-dd"
                :disabled="particulatePollutionReportFormTitle !== '新增微粒污染检验报告'
                  && particulatePollutionReportFormTitle !== '修改微粒污染检验报告'
                  && particulatePollutionReportFormTitle !== '微粒污染检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="检测标准">
              <el-input
                v-model="testStandard"
                type="textarea"
                :rows="4"
                readonly
                :disabled="particulatePollutionReportFormTitle !== '新增微粒污染检验报告'
                  && particulatePollutionReportFormTitle !== '修改微粒污染检验报告'
                  && particulatePollutionReportFormTitle !== '微粒污染检验报告详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="微粒污染检测">
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="reportDetailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="testGroup"
              title="实验组"
              :content-render="{name: '$input'}"
            />
            <vxe-table-colgroup title="检测指标" header-align="center">
              <vxe-table-column
                field="detectIndex1"
                title="10μm及10μm以上的微粒数"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column
                field="detectIndex2"
                title="25μm及25μm以上的微粒数"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
            </vxe-table-colgroup>
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="结论" prop="conclusion">
              <el-radio-group
                v-model="particulatePollutionReportForm.conclusion"
                :disabled="particulatePollutionReportFormTitle !== '新增微粒污染检验报告'
                  && particulatePollutionReportFormTitle !== '修改微粒污染检验报告'
                  && particulatePollutionReportFormTitle !== '微粒污染检验报告详情'"
              >
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="particulatePollutionReportForm.remarks"
                placeholder="请输入备注"
                clearable
                :disabled="particulatePollutionReportFormTitle !== '新增微粒污染检验报告'
                  && particulatePollutionReportFormTitle !== '修改微粒污染检验报告'
                  && particulatePollutionReportFormTitle !== '微粒污染检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="复核结果" prop="reviewResult">
              <el-radio-group
                v-model="particulatePollutionReportForm.reviewResult"
                :disabled="particulatePollutionReportFormTitle !== '复核微粒污染检验报告'
                  && particulatePollutionReportFormTitle !== '微粒污染检验报告详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准结果" prop="approvalResult">
              <el-radio-group
                v-model="particulatePollutionReportForm.approvalResult"
                :disabled="particulatePollutionReportFormTitle !== '批准微粒污染检验报告'
                  && particulatePollutionReportFormTitle !== '微粒污染检验报告详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
      <div slot="footer">
        <el-button @click="particulatePollutionReportDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="particulatePollutionReportFormSubmit">
          确 定
        </el-button>
        <el-button
          v-if="particulatePollutionReportFormTitle === '微粒污染检验报告详情'"
          type="primary"
          @click="getPdf"
        >
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="批号">
        <el-input v-model="searchForm.batchNo" placeholder="请输入批号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['PARTICULATE_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="particulatePollutionReportPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="formNo" label="编号" />
      <el-table-column prop="name" label="名称" />
      <el-table-column prop="spec" label="规格型号" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="inspectionQuantity" label="抽检数量" />
      <el-table-column label="报告日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reportDate">{{ scope.row.reportDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="结论">
        <template slot-scope="scope">
          <span v-if="scope.row.conclusion === 0">不合格</span>
          <span v-if="scope.row.conclusion === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="inspector2" label="检验人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.inspectionDate">{{ scope.row.inspectionDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewDate">{{ scope.row.reviewDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="复核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewResult === 1">通过</span>
          <span v-if="scope.row.reviewResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column prop="approver" label="批准人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approvalDate">{{ scope.row.approvalDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="批准结果">
        <template slot-scope="scope">
          <span v-if="scope.row.approvalResult === 1">通过</span>
          <span v-if="scope.row.approvalResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['PARTICULATE_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['PARTICULATE_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['PARTICULATE_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            复核
          </el-button>
          <el-button
            v-if="checkPermission(['PARTICULATE_APPROVAL']) && scope.row.status === 2"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleApproval(scope.$index, scope.row)"
          >
            批准
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="particulatePollutionReportPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addParticulatePollutionReport,
  deleteParticulatePollutionReport,
  updateParticulatePollutionReport,
  selectParticulatePollutionReportInfo,
  selectParticulatePollutionReportList,
  reviewParticulatePollutionReport,
  approvalParticulatePollutionReport
} from '@/api/quality/particulatePollutionReport'
import moment from 'moment'
import html2PDF from "jspdf-html2canvas";
import { Loading } from "element-ui";

export default {
  data () {
    return {
      particulatePollutionReportDialogVisible: false,
      particulatePollutionReportFormTitle: '',
      particulatePollutionReportForm: {
        id: '',
        formNo: '',
        name: '',
        spec: '',
        batchNo: '',
        inspectionQuantity: '',
        reportDate: '',
        conclusion: '',
        remarks: '',
        reviewResult: '',
        approvalResult: '',
        status: '',
        reportDetailJson: '',
        taskId: ''
      },
      particulatePollutionReportFormRules: {
        batchNo: [{ required: true, message: '批号不能为空', trigger: ['blur', 'change']}]
      },
      particulatePollutionReportPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        batchNo: ''
      },
      testStandard: '产品：每lml浸提液中含10μm及10μm以上的微粒数不得过30粒，含25μm及25μm以上的微粒数不得过10粒。\n初包装袋：每lml浸提液中含10μm及10μm以上的微粒数不得过30粒，含25μm及25μm以上的微粒数不得过10粒。',
      reportDetailList: [
        { testGroup: '实验组1' },
        { testGroup: '实验组2' },
        { testGroup: '实验组3' },
        { testGroup: '平均値' }
      ]
    }
  },
  created () {
    selectParticulatePollutionReportList(this.searchForm).then(res => {
      this.particulatePollutionReportPage = res
    })
  },
  methods: {
    particulatePollutionReportDialogClose () {
      this.$refs.particulatePollutionReportFormRef.resetFields()
      this.reportDetailList = [
        { testGroup: '实验组1' },
        { testGroup: '实验组2' },
        { testGroup: '实验组3' },
        { testGroup: '平均値' }
      ]
    },
    particulatePollutionReportFormSubmit () {
      if (this.particulatePollutionReportFormTitle === '微粒污染检验报告详情') {
        this.particulatePollutionReportDialogVisible = false
        return
      }
      this.$refs.particulatePollutionReportFormRef.validate(async valid => {
        if (valid) {
          if (this.particulatePollutionReportFormTitle === '新增微粒污染检验报告') {
            this.particulatePollutionReportForm.id = ''
            this.particulatePollutionReportForm.status = 1
            this.particulatePollutionReportForm.reportDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await addParticulatePollutionReport(this.particulatePollutionReportForm)
          } else if (this.particulatePollutionReportFormTitle === '修改微粒污染检验报告') {
            this.particulatePollutionReportForm.reportDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await updateParticulatePollutionReport(this.particulatePollutionReportForm)
          } else if (this.particulatePollutionReportFormTitle === '复核微粒污染检验报告') {
            this.particulatePollutionReportForm.status = 2
            await reviewParticulatePollutionReport(this.particulatePollutionReportForm)
          } else if (this.particulatePollutionReportFormTitle === '批准微粒污染检验报告') {
            this.particulatePollutionReportForm.status = 3
            await approvalParticulatePollutionReport(this.particulatePollutionReportForm)
          }
          this.particulatePollutionReportPage = await selectParticulatePollutionReportList(this.searchForm)
          this.particulatePollutionReportDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.particulatePollutionReportFormTitle = '新增微粒污染检验报告'
      this.particulatePollutionReportDialogVisible = true
      this.particulatePollutionReportForm.formNo = 'WL' + moment().format('YYYYMMDD') + '-'
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteParticulatePollutionReport(row.id)
        if (this.particulatePollutionReportPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.particulatePollutionReportPage = await selectParticulatePollutionReportList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.particulatePollutionReportFormTitle = '修改微粒污染检验报告'
      this.particulatePollutionReportDialogVisible = true
      this.selectParticulatePollutionReportInfoById(row.id)
    },
    handleReview (index, row) {
      this.particulatePollutionReportFormTitle = '复核微粒污染检验报告'
      this.particulatePollutionReportDialogVisible = true
      this.selectParticulatePollutionReportInfoById(row.id)
    },
    handleApproval (index, row) {
      this.particulatePollutionReportFormTitle = '批准微粒污染检验报告'
      this.particulatePollutionReportDialogVisible = true
      this.selectParticulatePollutionReportInfoById(row.id)
    },
    handleInfo (index, row) {
      this.particulatePollutionReportFormTitle = '微粒污染检验报告详情'
      this.particulatePollutionReportDialogVisible = true
      this.selectParticulatePollutionReportInfoById(row.id)
    },
    selectParticulatePollutionReportInfoById (id) {
      selectParticulatePollutionReportInfo(id).then(res => {
        this.particulatePollutionReportForm.id = res.id
        this.particulatePollutionReportForm.formNo = res.formNo
        this.particulatePollutionReportForm.name = res.name
        this.particulatePollutionReportForm.spec = res.spec
        this.particulatePollutionReportForm.batchNo = res.batchNo
        this.particulatePollutionReportForm.inspectionQuantity = res.inspectionQuantity
        this.particulatePollutionReportForm.reportDate = res.reportDate
        this.particulatePollutionReportForm.conclusion = res.conclusion
        this.particulatePollutionReportForm.remarks = res.remarks
        this.particulatePollutionReportForm.reviewResult = res.reviewResult
        this.particulatePollutionReportForm.approvalResult = res.approvalResult
        this.particulatePollutionReportForm.status = res.status
        this.particulatePollutionReportForm.taskId = res.taskId
        this.reportDetailList = res.reportDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectParticulatePollutionReportList(this.searchForm).then(res => {
        this.particulatePollutionReportPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectParticulatePollutionReportList(this.searchForm).then(res => {
        this.particulatePollutionReportPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectParticulatePollutionReportList(this.searchForm).then(res => {
        this.particulatePollutionReportPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['PARTICULATE_REVIEW']) && row.status === 1) {
        return 'review'
      } else if (this.checkPermission(['PARTICULATE_APPROVAL']) && row.status === 2) {
        return 'review'
      }
      return ''
    },
    getPdf () {
      let loading = ''
      let dom = document.getElementById('pdfDom')
      html2PDF(dom, {
        jsPDF: {
          unit: 'px',
          format: 'a4'
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        },
        output: `微粒污染检验报告${this.particulatePollutionReportForm.formNo}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: 'pdf加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        success: function (pdf) {
          pdf.save(this.output)
          loading.close()
        }
      })
    }
  }
}
</script>

<style>
</style>
